import { Configuration } from '@/api';
// eslint-disable-next-line import/no-cycle
import { useUserStore } from '@/stores/UserStore';

// eslint-disable-next-line import/prefer-default-export
export const getApiConfiguration = () => {
  const userStore = useUserStore();

  return new Configuration({
    basePath: process.env.VUE_APP_API_URL,
    headers: { Authorization: `Bearer ${userStore.token}` },
  });
};
