import { createI18n } from 'vue-i18n';
import messages from '@/locales';

// eslint-disable-next-line no-shadow
enum Locales {
  CS = 'cs'
}

export default createI18n({
  locale: Locales.CS,
  fallbackLocale: Locales.CS,
  messages,
});
