/* tslint:disable */
/* eslint-disable */
/**
 * Intee
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AppExceptionResponse,
    AppExceptionResponseFromJSON,
    AppExceptionResponseToJSON,
    CategoryModel,
    CategoryModelFromJSON,
    CategoryModelToJSON,
    CategoryTeaserModel,
    CategoryTeaserModelFromJSON,
    CategoryTeaserModelToJSON,
    PostModel,
    PostModelFromJSON,
    PostModelToJSON,
} from '../models';

export interface GetCategoryBySlugRequest {
    categorySlug: string;
}

export interface GetPostsByCategorySlugRequest {
    categorySlug: string;
    count?: number;
    skip?: number;
}

/**
 * 
 */
export class CategoriesApi extends runtime.BaseAPI {

    /**
     */
    async getCategoryBySlugRaw(requestParameters: GetCategoryBySlugRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CategoryModel>> {
        if (requestParameters.categorySlug === null || requestParameters.categorySlug === undefined) {
            throw new runtime.RequiredError('categorySlug','Required parameter requestParameters.categorySlug was null or undefined when calling getCategoryBySlug.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Categories/{categorySlug}`.replace(`{${"categorySlug"}}`, encodeURIComponent(String(requestParameters.categorySlug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CategoryModelFromJSON(jsonValue));
    }

    /**
     */
    async getCategoryBySlug(requestParameters: GetCategoryBySlugRequest, initOverrides?: RequestInit): Promise<CategoryModel> {
        const response = await this.getCategoryBySlugRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getMajorCategoriesRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<CategoryTeaserModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Categories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CategoryTeaserModelFromJSON));
    }

    /**
     */
    async getMajorCategories(initOverrides?: RequestInit): Promise<Array<CategoryTeaserModel>> {
        const response = await this.getMajorCategoriesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getPostsByCategorySlugRaw(requestParameters: GetPostsByCategorySlugRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<PostModel>>> {
        if (requestParameters.categorySlug === null || requestParameters.categorySlug === undefined) {
            throw new runtime.RequiredError('categorySlug','Required parameter requestParameters.categorySlug was null or undefined when calling getPostsByCategorySlug.');
        }

        const queryParameters: any = {};

        if (requestParameters.count !== undefined) {
            queryParameters['count'] = requestParameters.count;
        }

        if (requestParameters.skip !== undefined) {
            queryParameters['skip'] = requestParameters.skip;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Categories/{categorySlug}/Posts`.replace(`{${"categorySlug"}}`, encodeURIComponent(String(requestParameters.categorySlug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PostModelFromJSON));
    }

    /**
     */
    async getPostsByCategorySlug(requestParameters: GetPostsByCategorySlugRequest, initOverrides?: RequestInit): Promise<Array<PostModel>> {
        const response = await this.getPostsByCategorySlugRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
