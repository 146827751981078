/* tslint:disable */
/* eslint-disable */
/**
 * Intee
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CategorySubcategoryModel,
    CategorySubcategoryModelFromJSON,
    CategorySubcategoryModelFromJSONTyped,
    CategorySubcategoryModelToJSON,
} from './CategorySubcategoryModel';

/**
 * 
 * @export
 * @interface CategoryModel
 */
export interface CategoryModel {
    /**
     * 
     * @type {string}
     * @memberof CategoryModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryModel
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CategoryModel
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryModel
     */
    readonly thumbnail: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryModel
     */
    readonly thumbnailLazy: string;
    /**
     * 
     * @type {CategorySubcategoryModel}
     * @memberof CategoryModel
     */
    parent?: CategorySubcategoryModel;
    /**
     * 
     * @type {Array<CategorySubcategoryModel>}
     * @memberof CategoryModel
     */
    subcategories?: Array<CategorySubcategoryModel> | null;
}

export function CategoryModelFromJSON(json: any): CategoryModel {
    return CategoryModelFromJSONTyped(json, false);
}

export function CategoryModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CategoryModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'slug': json['slug'],
        'thumbnail': json['thumbnail'],
        'thumbnailLazy': json['thumbnailLazy'],
        'parent': !exists(json, 'parent') ? undefined : CategorySubcategoryModelFromJSON(json['parent']),
        'subcategories': !exists(json, 'subcategories') ? undefined : (json['subcategories'] === null ? null : (json['subcategories'] as Array<any>).map(CategorySubcategoryModelFromJSON)),
    };
}

export function CategoryModelToJSON(value?: CategoryModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'slug': value.slug,
        'parent': CategorySubcategoryModelToJSON(value.parent),
        'subcategories': value.subcategories === undefined ? undefined : (value.subcategories === null ? null : (value.subcategories as Array<any>).map(CategorySubcategoryModelToJSON)),
    };
}

