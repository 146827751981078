/* tslint:disable */
/* eslint-disable */
/**
 * Intee
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CategoryTeaserModel
 */
export interface CategoryTeaserModel {
    /**
     * 
     * @type {string}
     * @memberof CategoryTeaserModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryTeaserModel
     */
    readonly thumbnail: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryTeaserModel
     */
    readonly thumbnailLazy: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryTeaserModel
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryTeaserModel
     */
    parentName?: string | null;
}

export function CategoryTeaserModelFromJSON(json: any): CategoryTeaserModel {
    return CategoryTeaserModelFromJSONTyped(json, false);
}

export function CategoryTeaserModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CategoryTeaserModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'thumbnail': json['thumbnail'],
        'thumbnailLazy': json['thumbnailLazy'],
        'slug': json['slug'],
        'parentName': !exists(json, 'parentName') ? undefined : json['parentName'],
    };
}

export function CategoryTeaserModelToJSON(value?: CategoryTeaserModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'slug': value.slug,
        'parentName': value.parentName,
    };
}

