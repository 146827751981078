export default {
  forgottenPassword: {
    title: 'Zapomenuté heslo',
    success: 'Žádost o obnovu hesla jsme přijali. Další instrukce dostaneš na e-mail.',
    submit: 'Obnovit heslo',
    backToLogin: 'Znám heslo',
  },
  login: {
    title: 'Přihlásit se',
    error: 'Zadal jsi neplatnou kombinaci uživatelského jména a hesla.',
    labels: {
      login: 'Uživatelské jméno nebo e-mail',
      password: 'Heslo',
    },
    submit: 'Přihlásit se',
    actions: {
      register: 'Nemám účet',
      forgottenPassword: 'Zapomněl jsem heslo',
    },
  },
  register: {
    title: 'Registrace',
  },
  resetPassword: {
    title: 'Změna hesla',
  },
};
