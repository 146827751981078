/* tslint:disable */
/* eslint-disable */
/**
 * Intee
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AppExceptionResponse,
    AppExceptionResponseFromJSON,
    AppExceptionResponseToJSON,
    UserBearerTokenModel,
    UserBearerTokenModelFromJSON,
    UserBearerTokenModelToJSON,
    UserForgottenPasswordModel,
    UserForgottenPasswordModelFromJSON,
    UserForgottenPasswordModelToJSON,
    UserLoginModel,
    UserLoginModelFromJSON,
    UserLoginModelToJSON,
    UserRegisterModel,
    UserRegisterModelFromJSON,
    UserRegisterModelToJSON,
    UserResetPasswordModel,
    UserResetPasswordModelFromJSON,
    UserResetPasswordModelToJSON,
} from '../models';

export interface ForgottenPasswordRequest {
    userForgottenPasswordModel?: UserForgottenPasswordModel;
}

export interface LoginRequest {
    userLoginModel?: UserLoginModel;
}

export interface RegisterRequest {
    userRegisterModel?: UserRegisterModel;
}

export interface ResetPasswordRequest {
    userResetPasswordModel?: UserResetPasswordModel;
}

/**
 * 
 */
export class AuthApi extends runtime.BaseAPI {

    /**
     */
    async forgottenPasswordRaw(requestParameters: ForgottenPasswordRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Auth/ForgottenPassword`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserForgottenPasswordModelToJSON(requestParameters.userForgottenPasswordModel),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async forgottenPassword(requestParameters: ForgottenPasswordRequest = {}, initOverrides?: RequestInit): Promise<object> {
        const response = await this.forgottenPasswordRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async loginRaw(requestParameters: LoginRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserBearerTokenModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Auth/Login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserLoginModelToJSON(requestParameters.userLoginModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserBearerTokenModelFromJSON(jsonValue));
    }

    /**
     */
    async login(requestParameters: LoginRequest = {}, initOverrides?: RequestInit): Promise<UserBearerTokenModel> {
        const response = await this.loginRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async registerRaw(requestParameters: RegisterRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserBearerTokenModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Auth/Register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserRegisterModelToJSON(requestParameters.userRegisterModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserBearerTokenModelFromJSON(jsonValue));
    }

    /**
     */
    async register(requestParameters: RegisterRequest = {}, initOverrides?: RequestInit): Promise<UserBearerTokenModel> {
        const response = await this.registerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async resetPasswordRaw(requestParameters: ResetPasswordRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserBearerTokenModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Auth/ChangePassword`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserResetPasswordModelToJSON(requestParameters.userResetPasswordModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserBearerTokenModelFromJSON(jsonValue));
    }

    /**
     */
    async resetPassword(requestParameters: ResetPasswordRequest = {}, initOverrides?: RequestInit): Promise<UserBearerTokenModel> {
        const response = await this.resetPasswordRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
