import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/HomeView.vue'),
  },
  {
    path: '/autorizace',
    name: 'Auth',
    redirect: { name: 'AuthLogin' },
    component: () => import('@/views/auth/AuthView.vue'),
    children: [
      {
        path: 'prihlaseni',
        name: 'AuthLogin',
        component: () => import('@/views/auth/LoginView.vue'),
      },
      {
        path: 'registrace',
        name: 'Register',
        component: () => import('@/views/auth/RegisterView.vue'),
      },
      {
        path: 'zapomenute-heslo',
        name: 'ForgottenPassword',
        component: () => import('@/views/auth/ForgottenPasswordView.vue'),
      },
      {
        path: 'obnovit-heslo',
        name: 'ResetPassword',
        component: () => import('@/views/auth/ResetPasswordView.vue'),
      },
    ],
  },
  {
    path: '/nastaveni',
    component: () => import('@/views/settings/SettingsView.vue'),
    children: [
      {
        path: '',
        name: 'GeneralSettings',
        component: () => import('@/views/settings/GeneralSettingsView.vue'),
      },
      {
        path: 'heslo',
        name: 'PasswordSettings',
        component: () => import('@/views/settings/PasswordSettingsView.vue'),
      },
    ],
  },
  {
    path: '/prispevky/:id',
    name: 'PostDetail',
    component: () => import('@/views/post/PostDetailView.vue'),
    props: true,
  },
  {
    path: '/kategorie/:slug',
    name: 'CategoryDetail',
    component: () => import('@/views/category/CategoryDetailView.vue'),
    props: true,
  },
  {
    path: '/stranka',
    component: () => import('@/views/page/PageView.vue'),
    children: [
      {
        path: 'podminky',
        name: 'Terms',
        component: () => import('@/views/page/TermsView.vue'),
      },
      {
        path: 'soukromi',
        name: 'Privacy',
        component: () => import('@/views/page/PrivacyView.vue'),
      },
      {
        path: 'o-nas',
        name: 'About',
        component: () => import('@/views/page/AboutView.vue'),
      },
      {
        path: 'kontakty',
        name: 'Contacts',
        component: () => import('@/views/page/ContactsView.vue'),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
