/* tslint:disable */
/* eslint-disable */
/**
 * Intee
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserRegisterModel
 */
export interface UserRegisterModel {
    /**
     * 
     * @type {string}
     * @memberof UserRegisterModel
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegisterModel
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegisterModel
     */
    password: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserRegisterModel
     */
    company: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserRegisterModel
     */
    acceptTerms: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserRegisterModel
     */
    acceptDeclaration: boolean;
}

export function UserRegisterModelFromJSON(json: any): UserRegisterModel {
    return UserRegisterModelFromJSONTyped(json, false);
}

export function UserRegisterModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserRegisterModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'username': json['username'],
        'password': json['password'],
        'company': json['company'],
        'acceptTerms': json['acceptTerms'],
        'acceptDeclaration': json['acceptDeclaration'],
    };
}

export function UserRegisterModelToJSON(value?: UserRegisterModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'username': value.username,
        'password': value.password,
        'company': value.company,
        'acceptTerms': value.acceptTerms,
        'acceptDeclaration': value.acceptDeclaration,
    };
}

