/* tslint:disable */
/* eslint-disable */
/**
 * Intee
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OfferCreatePlaceModel,
    OfferCreatePlaceModelFromJSON,
    OfferCreatePlaceModelFromJSONTyped,
    OfferCreatePlaceModelToJSON,
} from './OfferCreatePlaceModel';
import {
    PostType,
    PostTypeFromJSON,
    PostTypeFromJSONTyped,
    PostTypeToJSON,
} from './PostType';

/**
 * 
 * @export
 * @interface PostFormModel
 */
export interface PostFormModel {
    /**
     * 
     * @type {string}
     * @memberof PostFormModel
     */
    title?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof PostFormModel
     */
    categories?: Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof PostFormModel
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostFormModel
     */
    reward?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PostFormModel
     */
    expert?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostFormModel
     */
    isVirtualPlace?: boolean;
    /**
     * 
     * @type {PostType}
     * @memberof PostFormModel
     */
    type?: PostType;
    /**
     * 
     * @type {Array<OfferCreatePlaceModel>}
     * @memberof PostFormModel
     */
    places?: Array<OfferCreatePlaceModel> | null;
    /**
     * 
     * @type {Date}
     * @memberof PostFormModel
     */
    eventDateFrom?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof PostFormModel
     */
    eventDateTo?: Date | null;
}

export function PostFormModelFromJSON(json: any): PostFormModel {
    return PostFormModelFromJSONTyped(json, false);
}

export function PostFormModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostFormModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': !exists(json, 'title') ? undefined : json['title'],
        'categories': !exists(json, 'categories') ? undefined : json['categories'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'reward': !exists(json, 'reward') ? undefined : json['reward'],
        'expert': !exists(json, 'expert') ? undefined : json['expert'],
        'isVirtualPlace': !exists(json, 'isVirtualPlace') ? undefined : json['isVirtualPlace'],
        'type': !exists(json, 'type') ? undefined : PostTypeFromJSON(json['type']),
        'places': !exists(json, 'places') ? undefined : (json['places'] === null ? null : (json['places'] as Array<any>).map(OfferCreatePlaceModelFromJSON)),
        'eventDateFrom': !exists(json, 'eventDateFrom') ? undefined : (json['eventDateFrom'] === null ? null : new Date(json['eventDateFrom'])),
        'eventDateTo': !exists(json, 'eventDateTo') ? undefined : (json['eventDateTo'] === null ? null : new Date(json['eventDateTo'])),
    };
}

export function PostFormModelToJSON(value?: PostFormModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'categories': value.categories,
        'description': value.description,
        'reward': value.reward,
        'expert': value.expert,
        'isVirtualPlace': value.isVirtualPlace,
        'type': PostTypeToJSON(value.type),
        'places': value.places === undefined ? undefined : (value.places === null ? null : (value.places as Array<any>).map(OfferCreatePlaceModelToJSON)),
        'eventDateFrom': value.eventDateFrom === undefined ? undefined : (value.eventDateFrom === null ? null : value.eventDateFrom.toISOString()),
        'eventDateTo': value.eventDateTo === undefined ? undefined : (value.eventDateTo === null ? null : value.eventDateTo.toISOString()),
    };
}

