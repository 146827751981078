import auth from './auth';
import common from './common';
import footer from './footer';
import navbar from './navbar';
import settings from './settings';

export default {
  auth,
  common,
  footer,
  navbar,
  settings,
};
