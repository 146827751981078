// Styles
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/lib/styles/main.sass';

// Vuetify
import { createVuetify } from 'vuetify';

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  defaults: {
    VBtn: {
      flat: true,
    },
  },
  display: {
    thresholds: {
      lg: 1280,
      xl: 1280,
      xxl: 1280,
    },
  },
  theme: {
    defaultTheme: 'intee',
    themes: {
      intee: {
        dark: false,
        colors: {
          primary: '#85C318',
          secondary: '#1F222B',
          error: '#B00020',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FB8C00',
        },
      },
    },
  },
});
