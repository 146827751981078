/* tslint:disable */
/* eslint-disable */
/**
 * Intee
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ErrorCodesEnum {
    AdminCategoriesMissingName = 'AdminCategoriesMissingName',
    AdminCategoriesMissingSlug = 'AdminCategoriesMissingSlug',
    AdminPostsNoUnclassifiedPosts = 'AdminPostsNoUnclassifiedPosts',
    CategoryNotFound = 'CategoryNotFound',
    ChangePasswordExpiredResetCode = 'ChangePasswordExpiredResetCode',
    ChangePasswordUsedResetCode = 'ChangePasswordUsedResetCode',
    PasswordTokenNotFound = 'PasswordTokenNotFound',
    PostFormRequiredAuthor = 'PostFormRequiredAuthor',
    PostFormRequiredCategories = 'PostFormRequiredCategories',
    PostFormRequiredPlace = 'PostFormRequiredPlace',
    PostFormRequiredTitle = 'PostFormRequiredTitle',
    PostFormUnauthorized = 'PostFormUnauthorized',
    PostNotFound = 'PostNotFound',
    UndefinedError = 'UndefinedError',
    UserAuthNotFound = 'UserAuthNotFound',
    UserAuthRequiredAcceptDeclaration = 'UserAuthRequiredAcceptDeclaration',
    UserAuthRequiredAcceptTerms = 'UserAuthRequiredAcceptTerms',
    UserAuthRequiredEmail = 'UserAuthRequiredEmail',
    UserAuthRequiredPassword = 'UserAuthRequiredPassword',
    UserAuthRequiredUsername = 'UserAuthRequiredUsername',
    UserAuthRequiredValidEmail = 'UserAuthRequiredValidEmail',
    UserAuthRequiredValidUsername = 'UserAuthRequiredValidUsername',
    UserAuthTakenEmail = 'UserAuthTakenEmail',
    UserAuthTakenUsername = 'UserAuthTakenUsername',
    UserForgottenPasswordExist = 'UserForgottenPasswordExist',
    UserNotFound = 'UserNotFound',
    PostFormRequiredType = 'PostFormRequiredType'
}

export function ErrorCodesEnumFromJSON(json: any): ErrorCodesEnum {
    return ErrorCodesEnumFromJSONTyped(json, false);
}

export function ErrorCodesEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ErrorCodesEnum {
    return json as ErrorCodesEnum;
}

export function ErrorCodesEnumToJSON(value?: ErrorCodesEnum | null): any {
    return value as any;
}

