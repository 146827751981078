/* tslint:disable */
/* eslint-disable */
/**
 * Intee
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserModel
 */
export interface UserModel {
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    fullname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    readonly name: string;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserModel
     */
    rating?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserModel
     */
    isCompany: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    readonly photo: string;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    readonly photoLazy: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserModel
     */
    scope?: Array<string> | null;
}

export function UserModelFromJSON(json: any): UserModel {
    return UserModelFromJSONTyped(json, false);
}

export function UserModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'username': json['username'],
        'fullname': !exists(json, 'fullname') ? undefined : json['fullname'],
        'name': json['name'],
        'email': json['email'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'rating': !exists(json, 'rating') ? undefined : json['rating'],
        'isCompany': json['isCompany'],
        'photo': json['photo'],
        'photoLazy': json['photoLazy'],
        'scope': !exists(json, 'scope') ? undefined : json['scope'],
    };
}

export function UserModelToJSON(value?: UserModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'username': value.username,
        'fullname': value.fullname,
        'email': value.email,
        'description': value.description,
        'rating': value.rating,
        'isCompany': value.isCompany,
        'scope': value.scope,
    };
}

