/* tslint:disable */
/* eslint-disable */
/**
 * Intee
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserUpdateModel
 */
export interface UserUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof UserUpdateModel
     */
    fullname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateModel
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateModel
     */
    username?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateModel
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateModel
     */
    currentPassword?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateModel
     */
    newPassword?: string | null;
}

export function UserUpdateModelFromJSON(json: any): UserUpdateModel {
    return UserUpdateModelFromJSONTyped(json, false);
}

export function UserUpdateModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserUpdateModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fullname': !exists(json, 'fullname') ? undefined : json['fullname'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'username': !exists(json, 'username') ? undefined : json['username'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'currentPassword': !exists(json, 'currentPassword') ? undefined : json['currentPassword'],
        'newPassword': !exists(json, 'newPassword') ? undefined : json['newPassword'],
    };
}

export function UserUpdateModelToJSON(value?: UserUpdateModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fullname': value.fullname,
        'email': value.email,
        'username': value.username,
        'description': value.description,
        'currentPassword': value.currentPassword,
        'newPassword': value.newPassword,
    };
}

