/* tslint:disable */
/* eslint-disable */
/**
 * Intee
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserResetPasswordModel
 */
export interface UserResetPasswordModel {
    /**
     * 
     * @type {string}
     * @memberof UserResetPasswordModel
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof UserResetPasswordModel
     */
    password: string;
}

export function UserResetPasswordModelFromJSON(json: any): UserResetPasswordModel {
    return UserResetPasswordModelFromJSONTyped(json, false);
}

export function UserResetPasswordModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserResetPasswordModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': json['code'],
        'password': json['password'],
    };
}

export function UserResetPasswordModelToJSON(value?: UserResetPasswordModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'password': value.password,
    };
}

