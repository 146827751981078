import {
  AuthApi, UserForgottenPasswordModel, UserLoginModel,
  UserModel, UserRegisterModel, UserResetPasswordModel, UsersApi, UserUpdateModel,
} from '@/api';
// eslint-disable-next-line import/no-cycle
import { getApiConfiguration } from '@/helpers/api';
import { defineStore } from 'pinia';

// eslint-disable-next-line import/prefer-default-export
export const useUserStore = defineStore('user', {
  state: () => ({
    loading: false,

    token: localStorage.getItem('authToken') ?? '',

    userData: {} as UserModel,
  }),

  getters: {
    isLoggedIn: (state) => Object.keys(state.userData).length > 0,
  },

  actions: {
    async getUserData() {
      if (this.token) {
        const usersApi = new UsersApi(getApiConfiguration());
        const userData = await usersApi.me();

        this.userData = userData;
      }
    },

    async login(userLoginModel: UserLoginModel) {
      this.loading = true;

      const authApi = new AuthApi(getApiConfiguration());
      const { token } = await authApi.login({ userLoginModel });

      localStorage.setItem('authToken', token);

      this.token = token;
      this.loading = false;

      this.getUserData();
    },

    async register(userRegisterModel: UserRegisterModel) {
      this.loading = true;

      const authApi = new AuthApi(getApiConfiguration());
      const { token } = await authApi.register({ userRegisterModel });

      localStorage.setItem('authToken', token);

      this.token = token;
      this.loading = false;

      this.getUserData();
    },

    async forgottenPassword(userForgottenPasswordModel: UserForgottenPasswordModel) {
      this.loading = true;

      const authApi = new AuthApi(getApiConfiguration());
      await authApi.forgottenPassword({ userForgottenPasswordModel });

      this.loading = false;
    },

    async resetPassword(userResetPasswordModel: UserResetPasswordModel) {
      this.loading = true;

      const authApi = new AuthApi(getApiConfiguration());
      const { token } = await authApi.resetPassword({ userResetPasswordModel });

      localStorage.setItem('authToken', token);

      this.token = token;
      this.loading = false;

      this.getUserData();
    },

    logout() {
      this.token = '';
      this.userData = {} as UserModel;
      localStorage.removeItem('authToken');
    },

    async update(userUpdateModel: UserUpdateModel) {
      this.loading = true;

      const usersApi = new UsersApi(getApiConfiguration());
      const userData = await usersApi.updateUser({ userUpdateModel });

      this.userData = userData;
      this.loading = false;
    },
  },
});
