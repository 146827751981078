/* tslint:disable */
/* eslint-disable */
/**
 * Intee
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CategoryModel,
    CategoryModelFromJSON,
    CategoryModelFromJSONTyped,
    CategoryModelToJSON,
} from './CategoryModel';
import {
    PostPlace,
    PostPlaceFromJSON,
    PostPlaceFromJSONTyped,
    PostPlaceToJSON,
} from './PostPlace';
import {
    PostType,
    PostTypeFromJSON,
    PostTypeFromJSONTyped,
    PostTypeToJSON,
} from './PostType';
import {
    UserModel,
    UserModelFromJSON,
    UserModelFromJSONTyped,
    UserModelToJSON,
} from './UserModel';

/**
 * 
 * @export
 * @interface PostModel
 */
export interface PostModel {
    /**
     * 
     * @type {string}
     * @memberof PostModel
     */
    id: string;
    /**
     * 
     * @type {UserModel}
     * @memberof PostModel
     */
    author: UserModel;
    /**
     * 
     * @type {PostType}
     * @memberof PostModel
     */
    type: PostType;
    /**
     * 
     * @type {string}
     * @memberof PostModel
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof PostModel
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostModel
     */
    reward?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostModel
     */
    readonly thumbnail: string;
    /**
     * 
     * @type {string}
     * @memberof PostModel
     */
    readonly thumbnailLazy: string;
    /**
     * 
     * @type {number}
     * @memberof PostModel
     */
    views?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PostModel
     */
    expert?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostModel
     */
    isVirtualPlace?: boolean;
    /**
     * 
     * @type {Array<PostPlace>}
     * @memberof PostModel
     */
    places: Array<PostPlace>;
    /**
     * 
     * @type {Array<CategoryModel>}
     * @memberof PostModel
     */
    categories: Array<CategoryModel>;
    /**
     * 
     * @type {Date}
     * @memberof PostModel
     */
    eventDateFrom?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof PostModel
     */
    eventDateTo?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof PostModel
     */
    createdDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof PostModel
     */
    updatedDate: Date;
}

export function PostModelFromJSON(json: any): PostModel {
    return PostModelFromJSONTyped(json, false);
}

export function PostModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'author': UserModelFromJSON(json['author']),
        'type': PostTypeFromJSON(json['type']),
        'title': json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'reward': !exists(json, 'reward') ? undefined : json['reward'],
        'thumbnail': json['thumbnail'],
        'thumbnailLazy': json['thumbnailLazy'],
        'views': !exists(json, 'views') ? undefined : json['views'],
        'expert': !exists(json, 'expert') ? undefined : json['expert'],
        'isVirtualPlace': !exists(json, 'isVirtualPlace') ? undefined : json['isVirtualPlace'],
        'places': ((json['places'] as Array<any>).map(PostPlaceFromJSON)),
        'categories': ((json['categories'] as Array<any>).map(CategoryModelFromJSON)),
        'eventDateFrom': !exists(json, 'eventDateFrom') ? undefined : (json['eventDateFrom'] === null ? null : new Date(json['eventDateFrom'])),
        'eventDateTo': !exists(json, 'eventDateTo') ? undefined : (json['eventDateTo'] === null ? null : new Date(json['eventDateTo'])),
        'createdDate': (new Date(json['createdDate'])),
        'updatedDate': (new Date(json['updatedDate'])),
    };
}

export function PostModelToJSON(value?: PostModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'author': UserModelToJSON(value.author),
        'type': PostTypeToJSON(value.type),
        'title': value.title,
        'description': value.description,
        'reward': value.reward,
        'views': value.views,
        'expert': value.expert,
        'isVirtualPlace': value.isVirtualPlace,
        'places': ((value.places as Array<any>).map(PostPlaceToJSON)),
        'categories': ((value.categories as Array<any>).map(CategoryModelToJSON)),
        'eventDateFrom': value.eventDateFrom === undefined ? undefined : (value.eventDateFrom === null ? null : value.eventDateFrom.toISOString()),
        'eventDateTo': value.eventDateTo === undefined ? undefined : (value.eventDateTo === null ? null : value.eventDateTo.toISOString()),
        'createdDate': (value.createdDate.toISOString()),
        'updatedDate': (value.updatedDate.toISOString()),
    };
}

