import { createPinia } from 'pinia';
import { createApp } from 'vue';
import linkify from 'vue-linkify';
import VueMapboxTs from 'vue-mapbox-ts';
import App from './App.vue';
import i18n from './plugins/i18n';
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';
import router from './router';

loadFonts();

const app = createApp(App);

app.directive('linkified', linkify);

app
  .use(createPinia())
  .use(router)
  .use(vuetify)
  .use(i18n)
  .use(VueMapboxTs)
  .mount('#app');
