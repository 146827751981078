/* tslint:disable */
/* eslint-disable */
/**
 * Intee
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AppExceptionResponse,
    AppExceptionResponseFromJSON,
    AppExceptionResponseToJSON,
    PostFormModel,
    PostFormModelFromJSON,
    PostFormModelToJSON,
    PostFormResponse,
    PostFormResponseFromJSON,
    PostFormResponseToJSON,
    PostModel,
    PostModelFromJSON,
    PostModelToJSON,
} from '../models';

export interface CreatePostRequest {
    postFormModel?: PostFormModel;
}

export interface GetPostRequest {
    postId: string;
}

export interface UpdatePostRequest {
    postId: string;
    postFormModel?: PostFormModel;
}

/**
 * 
 */
export class PostsApi extends runtime.BaseAPI {

    /**
     */
    async createPostRaw(requestParameters: CreatePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostFormResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Posts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostFormModelToJSON(requestParameters.postFormModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostFormResponseFromJSON(jsonValue));
    }

    /**
     */
    async createPost(requestParameters: CreatePostRequest = {}, initOverrides?: RequestInit): Promise<PostFormResponse> {
        const response = await this.createPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPostRaw(requestParameters: GetPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostModel>> {
        if (requestParameters.postId === null || requestParameters.postId === undefined) {
            throw new runtime.RequiredError('postId','Required parameter requestParameters.postId was null or undefined when calling getPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Posts/{postId}`.replace(`{${"postId"}}`, encodeURIComponent(String(requestParameters.postId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostModelFromJSON(jsonValue));
    }

    /**
     */
    async getPost(requestParameters: GetPostRequest, initOverrides?: RequestInit): Promise<PostModel> {
        const response = await this.getPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listMyPostsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<PostModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Posts/My`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PostModelFromJSON));
    }

    /**
     */
    async listMyPosts(initOverrides?: RequestInit): Promise<Array<PostModel>> {
        const response = await this.listMyPostsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async listPostsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<PostModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Posts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PostModelFromJSON));
    }

    /**
     */
    async listPosts(initOverrides?: RequestInit): Promise<Array<PostModel>> {
        const response = await this.listPostsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async updatePostRaw(requestParameters: UpdatePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.postId === null || requestParameters.postId === undefined) {
            throw new runtime.RequiredError('postId','Required parameter requestParameters.postId was null or undefined when calling updatePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Posts/{postId}`.replace(`{${"postId"}}`, encodeURIComponent(String(requestParameters.postId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PostFormModelToJSON(requestParameters.postFormModel),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async updatePost(requestParameters: UpdatePostRequest, initOverrides?: RequestInit): Promise<object> {
        const response = await this.updatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
