/* tslint:disable */
/* eslint-disable */
/**
 * Intee
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostPlace
 */
export interface PostPlace {
    /**
     * 
     * @type {number}
     * @memberof PostPlace
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PostPlace
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PostPlace
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof PostPlace
     */
    longitude?: number;
}

export function PostPlaceFromJSON(json: any): PostPlace {
    return PostPlaceFromJSONTyped(json, false);
}

export function PostPlaceFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostPlace {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
    };
}

export function PostPlaceToJSON(value?: PostPlace | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'latitude': value.latitude,
        'longitude': value.longitude,
    };
}

