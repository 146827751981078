/* tslint:disable */
/* eslint-disable */
/**
 * Intee
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserForgottenPasswordModel
 */
export interface UserForgottenPasswordModel {
    /**
     * 
     * @type {string}
     * @memberof UserForgottenPasswordModel
     */
    login: string;
}

export function UserForgottenPasswordModelFromJSON(json: any): UserForgottenPasswordModel {
    return UserForgottenPasswordModelFromJSONTyped(json, false);
}

export function UserForgottenPasswordModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserForgottenPasswordModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'login': json['login'],
    };
}

export function UserForgottenPasswordModelToJSON(value?: UserForgottenPasswordModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'login': value.login,
    };
}

