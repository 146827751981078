/* tslint:disable */
/* eslint-disable */
/**
 * Intee
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AppExceptionResponse,
    AppExceptionResponseFromJSON,
    AppExceptionResponseToJSON,
    UserModel,
    UserModelFromJSON,
    UserModelToJSON,
    UserUpdateModel,
    UserUpdateModelFromJSON,
    UserUpdateModelToJSON,
} from '../models';

export interface UpdateUserRequest {
    userUpdateModel?: UserUpdateModel;
}

/**
 * 
 */
export class UsersApi extends runtime.BaseAPI {

    /**
     */
    async meRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserModelFromJSON(jsonValue));
    }

    /**
     */
    async me(initOverrides?: RequestInit): Promise<UserModel> {
        const response = await this.meRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async updateUserRaw(requestParameters: UpdateUserRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Users`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserUpdateModelToJSON(requestParameters.userUpdateModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserModelFromJSON(jsonValue));
    }

    /**
     */
    async updateUser(requestParameters: UpdateUserRequest = {}, initOverrides?: RequestInit): Promise<UserModel> {
        const response = await this.updateUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
